import styled from "styled-components";
export const DefaultForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  .form-group,
  .action {
    display: block;
    margin-bottom: 20px;
  }
  .form-group {
    background-color: #ffffff;
    padding: 10px 20px;
    label {
      display: block;
      margin: 8px 0;
      color: #000000;
      font-family: "gk-medium";
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 1px;
      line-height: 15px;
      text-align: left;
      & .text-light {
        font-style: italic;
        font-weight: 300;
        font-family: "gk-light";
      }
      .required::after {
        content: "*";
        color: black;
        font-size: 12px;
        margin: 0 0 0 5px;
      }
    }
  }
  input:not(.checkbox),
  select {
    width: 100%;
    border: none;
    height: 34px;
    padding: 0;
    margin-bottom: 12px;
    border-bottom: solid 1px black;

    &:focus {
      box-shadow: none;
    }
  }
  select {
    background-color: #ffffff;
    option {
      font-family: "gk-medium";
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
    }
  }
  .checkbox-wrapper {
    color: #000000;

    input {
      display: inline;
      visibility: hidden;
    }
    .text-light {
      font-style: italic;
      font-family: "gk-light";
      font-weight: 300;
    }
    label {
      width: 90%;
      display: -webkit-inline-box;
      padding-left: 5px;
      font-family: "gk-medium";
      font-size: 10px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0.36px;
      line-height: 14px;
      text-align: left;
      a {
        color: #000;
      }
    }
  }
  .actions {
    position: static;
    display: flex;
    flex-direction: column-reverse;
    align-content: center;
    justify-content: center;
    .secondary {
      margin: 15px auto;
    }
    .primary {
      border: 0;
    }
  }
  .react-datepicker-wrapper {
    display: block !important;
  }
  input.datefield {
    margin: 0 10px;
    margin-bottom: 0;
  }
`;
