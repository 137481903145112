import React, { useState } from "react";
import "./success.css";
import brightfoam from "./img/brightfoam.png";
import hydrafusion from "./img/hydrafusion.png";
import brightcream from "./img/brightcream.png";
import firmingcream from "./img/firmingcream.png";
import antiwrinklecream from "./img/antiwrinklecream.png";
import antiflash from "./img/antiflash.png";
import { useHistory } from "react-router";

const getToday = () => {
  const date_ob = new Date();
  let date = ("0" + date_ob.getDate()).slice(-2);
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
  let year = date_ob.getFullYear();
  let hours = date_ob.getHours();
  let minutes = date_ob.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return date + "/" + month + "/" + year + " " + hours + ":" + minutes;
};
const objIMG = {
  brightfoam,
  hydrafusion,
  brightcream,
  firmingcream,
  antiwrinklecream,
  antiflash
};
const Success = ({ products, customerName }) => {
  const history = useHistory();
  const [cachedValue] = useState(localStorage.getItem('id_azafata'));

  const back = () => {
    window.location.href = `/?id=${cachedValue}`;
  }

  if (!customerName) history.push("/");

  const url = window.location.hostname;
  const caproig = "caproig";
  let text = false;
  let castellano = false;
  let sinregalo = true;
  let conregalo = false;
  const sorteoalma = "sorteofestivalalma";
  const iconica = "iconica";
  const universal = "universal";
  const forms = "forms";
  if(url.includes(iconica) || url.includes(universal) || url.includes(forms)) {
    castellano = true;
  }
  if(url.includes(sorteoalma)) {
    let sinregalo = false;
    conregalo = true;
  }
  return (
    <div className="success-page">
      <div>
        {text ? (
          <>
            <div className="bloque">
              <div className="date">{getToday() + "h"}</div>
              <h1 className="title">
                Hola {customerName}, ja pots recollir el teu regal a l'estand!{" "}
              </h1>
              <p className="subtitle">
              ya puedes recoger tu regalo en el stand!{" "}
              </p>
              <p style={{ marginBottom: "0px" }} className="catalan">
                Mostra al nostre estand aquesta pàgina o el pdf que et pots descarregar a continuació per recollir el teu obsequi.
              </p>
              <p style={{ marginBottom: "15px" }} className="espanol">
                <em>Haz una captura de pantalla a esta página o muéstrala directamente en nuestro stand para recoger tu obsequio. </em>
              </p>
              <p className="catalan" style={{ marginBottom: "0px" }}>
              Aviat rebràs un email per explicar-te com accedir a la promoció exclusiva del tractament professional {" "}<em>summer glow</em>{" "} més un netejador facial de regal.
              </p>
              <p style={{ marginBottom: "30px" }}  className="espanol">
              Pronto recibirás un email para contarte cómo acceder a la promoción exclusiva del tratamiento profesional 
                {" "}<em>summer glow</em>{" "}  más un {" "}limpiador facial de regalo. 
              </p>
              <div className="product-steps">
                {products.map((prod, index) => (
                  <a href={"#" + prod.id} className="img-content" key={index}>
                    <img src={objIMG[prod.id]} alt="" />
                    <p>{`paso ${index + 1}`}</p>
                  </a>
                ))}
              </div>
              <div className="divisor"></div>
            </div>
          </>
        ) : castellano ? (
          <>
            {sinregalo ? (
              <>
                <div className="bloque">
                  <div className="date">{getToday() + "h"}</div>
                  <h1 className="title" style={{ marginBottom: "30px" }} >
                    Hola {customerName},
                  </h1>
                  <p className="catalan">
                  Pronto recibirás un email para contarte cómo acceder a la <strong>promoción exclusiva del tratamiento</strong>.
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="bloque">
                  <div className="date">{getToday() + "h"}</div>
                  <h1 className="title" style={{ marginBottom: "30px" }} >
                    Hola {customerName},
                  </h1>
                  <p className="catalan">
                  Pronto recibirás un email para contarte cómo acceder a la <strong>promoción exclusiva del tratamiento y un protector solar de regalo.</strong>
                  </p>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {conregalo ? (
              <>
                <div className="bloque">
                <div className="date">{getToday() + "h"}</div>
                <h1 className="title">
                  Hola {customerName},{" "}
                </h1>
                <p style={{ marginBottom: "0px" }} className="catalan">
                Gràcies per participar en el sorteig! Quan acabi el festival, t'enviarem un mail si has resultat guanyador.
                </p>
                <p style={{ marginBottom: "15px" }} className="espanol">
                  <em>¡Gracias por participar en el sorteo! Cuando termine el festival, te enviaremos un mail si has resultado ganador.</em>
                </p>
              </div>
              </>
            ) : (
              <>
              <div className="bloque">
                <div className="date">{getToday() + "h"}</div>
                <h1 className="title">
                  Hola {customerName},{" "}
                </h1>
                <p className="catalan" style={{ marginBottom: "0px" }}>
                Aviat rebràs un email per a explicar-te com accedir a la <strong>promoció exclusiva del tractament i un protector solar de regal.</strong>
                </p>
                <p style={{ marginBottom: "30px" }}  className="espanol">
                <em>Pronto recibirás un email para contarte cómo acceder a la <strong>promoción exclusiva del tratamiento y un protector solar de regalo.</strong></em>
                </p>
              </div>
              </>
            )}
          </>
        )}

        {!sinregalo && !conregalo ? 
        <>
          {castellano ?
              <>
              {products.map((prod, index) => (
                <div
                  className="steps"
                  id={prod.id}
                  key={index}
                  onClick={() => window.open(prod.url)}
                >
                  <div className="title">
                    {" "}
                    <div className="img-content cp">
                      {" "}
                      <img src={objIMG[prod.id]} alt="" />{" "}
                    </div>{" "}
                    <h2 className="name-product">{prod.name}</h2>{" "}
                  </div>{" "}
                  <div className="info">
                    {" "}
                    <span className="catalan">{prod.es_description}</span>{" "}
                  </div>
                </div>
              ))}    
              </>
             : 
              <>
              {products.map((prod, index) => (
                <div
                  className="steps"
                  id={prod.id}
                  key={index}
                  onClick={() => window.open(prod.url)}
                >
                  <div className="title">
                    <div className="img-content cp">
                      <img src={objIMG[prod.id]} alt="" />
                    </div>
                    <h2 className="name-product">{prod.name}</h2>
                  </div>
                  <div className="info">
                    <span className="catalan">{prod.cat_description}</span>
                    <em>
                      {" "}
                      / <span>{prod.es_description}</span>
                    </em>
                  </div>
                </div>
              ))}
              </>
            }
        </> 
        : 
        ""
        }          
          
        <div className="button-container">
          <div className="button-return" onClick={() => back()}>
          {castellano ?
            <>
            <span className="catalan">Iniciar cuestionario </span>
            </>
            :
            <>
              <span className="catalan">Començar qüestionari</span>
              <span className="espanol"> / Iniciar cuestionario</span>
            </>
          }
          </div>
        </div>
      </div>
    </div>
  );
};
export default Success;
