import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Home from "./pages/Home";
import Questionnaire from "./pages/Questionnaire";
import Success from "./pages/Success";
import logo from "./logo.svg";
import "./App.css";
import Questionaire_json from "./questionnaire.json";
import Focus_Combinations from "./focus-combinations.json";
import NOFocus_Combinations from "./nofocus-combinations.json";
import Products_json from './products.json';
import { useAzafata } from './context/azafata.context';

const App = () => {
  const [questionnaire, setQuestionnaire] = useState(Questionaire_json);
  const [customerName,setCustomerName] = useState("");
  const [products, setProducts] = useState(Products_json);
  const [year, setYear] = useState();
  const {id} = useAzafata();
  const history = useHistory();

  const [cachedValue, setcachedValue] = useState(localStorage.getItem('id_azafata'));
  useEffect(() => {
    if (cachedValue !== id && id !== null) {
      // Actualizar la caché local con el nuevo valor
      localStorage.setItem('id_azafata', id);
      setcachedValue(id)
    }
  }, [id]);

  const back = () => {
      window.location.href = `/?id=${cachedValue}`;
  }

  const calculateResult = (questionnaire) => {
    let response;
    let today = new Date();
    const dob = year.split("/");
    let difference = today - new Date(dob[2], dob[1], dob[0]);
    let totalDays = difference / (1000 * 60 * 60 * 24);
    let totalYears = totalDays / 365;
    let age;
    if (totalYears < 35) {
      age = "<35";
    } else if (totalYears >= 35 && totalYears <= 45) {
      age = "35-45";
    } else if (totalYears > 45) {
      age = ">45";
    }
    questionnaire.questions[0].options.map((option) => {
      if (option.selected) return response = option.value;
    });
    const url = window.location.href;
    if(url.includes("vip")) {
      const productsResult = [];
      Focus_Combinations[response][age].map(combination => {
        const result = products.find(prod => prod.id === combination);
        if(result) productsResult.push(result)
      })
      setProducts(productsResult)
    }else{
      const productsResult = [];
      NOFocus_Combinations[response][age].map(combination => {
        const result = products.find(prod => prod.id === combination);
        if(result) productsResult.push(result);
      })
      setProducts(productsResult)
    }
    history.push("/success");
  };
  useEffect(() => {
    if (year) calculateResult(questionnaire);
    // eslint-disable-next-line
  }, [year]);
  return (
    <div>
      <nav className="header">
        <div onClick={() => back()}>
          <img src={logo} alt="Logo Mesoestetic" />
        </div>
        <div>
          <p>ID: {cachedValue}</p>
        </div>
      </nav>
      <main className="content">
        <div className="content-container">
          <Switch>
            <Route path="/success">
              <Success products={products} customerName={customerName} />
            </Route>
            <Route path="/questionnaire">
              <Questionnaire
                questionnaire={questionnaire}
                setQuestionnaire={setQuestionnaire}
                setYear={setYear}
                calculateResult={calculateResult}
                setCustomerName={setCustomerName}
              />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </main>
    </div>
  );
};

export default App;
