import React, { useState } from "react";
import { DefaultForm } from "./style";
import { useMailchimp, Status } from "./Mailchimp.hook";
import PDF from "./Términos_y_condiciones(ES).pdf";
import { useAzafata } from '../../context/azafata.context';
import { Link } from "react-router-dom";

const getTextValue = (questionnaire, questionID) => {
  let result;
  questionnaire.questions[questionID - 1].options.map((option) => {
    if (option.selected) return result = option.text_espanol;
  });
  return result;
};
const getFormattedDOB = (day, month, year) => {
  let formatted_date;
  formatted_date = `${day}/${month}/${year}`;
  return formatted_date;
};
const Form = ({
  questionnaire,
  setCounter,
  setYear,
  setCustomerName,
  history,
  setHistory
}) => {
  const { subscribe, status, error } = useMailchimp(
    "https://mesoestetic.us7.list-manage.com/subscribe/post?u=4b1641f9e6ae6f9ff5bddf08b&id=8fa28dde70&f_id=00198ae0f0"
  );
  const {id: azafataId} = useAzafata();
  const {comment} = useAzafata();
  const [cachedValue] = useState(localStorage.getItem('id_azafata'));
  
  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedDob = getFormattedDOB(
      e.target.dobDay.value,
      e.target.dobMonth.value,
      e.target.dobYear.value
    );
    const url = window.location.href;

    let formData = {
      EMAIL: e.target.EMAIL.value,
      FNAME: e.target.FNAME.value,
      GNERO: e.target.GENDER.value,
      CP: e.target.POSTALCODE.value,
      PIELSENSI: getTextValue(questionnaire, 2),
      TIPODEPIEL: getTextValue(questionnaire, 1),
      SOY: getTextValue(questionnaire, 3),
      ESCLIENTE: getTextValue(questionnaire, 4),
      CONOCIA: getTextValue(questionnaire, 7),
      CONOCEPOR: getTextValue(questionnaire, 8),
      // MMERGE11: subscriberType,
      MMERGE3: `${e.target.dobMonth.value}/${e.target.dobDay.value}/${e.target.dobYear.value}`,
    };
    if (comment !== "") {
      formData = {
        ...formData,
        CENTROEST: comment,
      };
    }
    else {
      formData = {
        ...formData,
        CENTROEST: '-',
      };
    }
    if(cachedValue) {
      formData = {
        ...formData,
        AZAFATA: cachedValue
      }
    }
    const timestamp = Date.now(); 
    if (url.includes("festivalpedralbes")) {
      formData = {
        ...formData,
        CODIGOP : `P${timestamp}`,
        "group[78037][1]": "1", //Grupo Pedralbes
      }
      
    } else if (url.includes("festivalpf")){
      formData = {
        ...formData,
        CODIGOPF : `PF${timestamp}`,
        "group[78037][2]": "1",  // Grupo Porta Ferrada
      }
    } else if (url.includes("festivalcaproig")){
      formData = {
        ...formData,
        CODIGOCR : `CR${timestamp}`,
        "group[78037][4]": "1",  // Grupo Cap Roig
      } 
    } else if (url.includes("iconica")){
        
        formData = {
          ...formData,
          CODIGOIS : `IS${timestamp}`,
          "group[78037][8]": "1",  // Grupo Icónica
      }
    } else if (url.includes("polopark")){
        
      formData = {
        ...formData,
        CODIGOPL : `PL${timestamp}`,
        "group[78037][16]": "1",  // Grupo Polo Park
      }
    } else if (url.includes("universal")){
      formData = {
        ...formData,
        CODIGOUN : `UN${timestamp}`,
        "group[78037][32]": "1",  // Grupo Universal
      }
    } else if (url.includes("sorteofestivalalma")){
      formData = {
        ...formData,
        CODIGOALMS : `ALMS${timestamp}`,
        "group[78037][128]": "1",  // Grupo Sorteo alma
      }
    } else if (url.includes("alma")){
      formData = {
        ...formData,
        CODIGOALM : `ALM${timestamp}`,
        "group[78037][64]": "1",  // Grupo Alma
      }
    }
    else if (url.includes("forms")){
      formData = {
        ...formData,
        CODIGOCG : `CG${timestamp}`,
        "group[78039][512]": "1",  // Antes Grupo Marbella, ahora christmas
      }
    }

    subscribe(formData);
    setCustomerName(formData.FNAME);
    setYear(formattedDob);
  };
  if (status === Status.loading) {
    console.log(status, "loading");
  }
  if (status === Status.error) {
    console.log(error);
  }
  const back = () => {
    const previousQuestionId = history.pop();
    setHistory([...history]);
    return setCounter(previousQuestionId);
  };
  let castellano = false;
  const url = window.location.href;
  const iconica = "iconica";
  const universal = "universal";
  const forms = "forms";
  const sorteoalma = "sorteofestivalalma";
  if(url.includes(iconica) || url.includes(universal) || url.includes(forms)) {
    castellano = true;
  }
  return (
    <DefaultForm onSubmit={handleSubmit}>
    {castellano ?
      <>
        <div className="form-group">
          <label htmlFor="email">
            <span className="required">Correo electrónico</span>
          </label>
          <input required type="email" name="EMAIL" />
          <label htmlFor="name">
            <span className="required">Nombre</span>
          </label>
          <input required type="text" name="FNAME" />
          <label htmlFor="gender">
            <span className="required">Género</span>
          </label>
          <select required name="GENDER" defaultValue="">
            <option value="" disabled>
             Selecciona el género
            </option>
            <option value="Hombre">Hombre</option>
            <option value="Mujer">Mujer</option>
            <option value="Otro">Otro</option>
          </select>
          <label htmlFor="dobdob-day">
            <span className="required">Fecha de nacimiento</span>
          </label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              className="datefield"
              type="number"
              name="dobDay"
              required
              placeholder="Día"
              min="1"
              max="31"
              style={{marginLeft: 0}}
            />
            /
            <input
              className="datefield"
              type="number"
              name="dobMonth"
              placeholder="Mes"
              required
              min="1"
              max="12"
            />
            /
            <input
              className="datefield"
              type="number"
              name="dobYear"
              placeholder="Año"
              required
              min="1900"
              max="2020"
              style={{marginRight: 0}}

            />
          </div>
          <label htmlFor="city" style={{marginTop: "20px"}}>
            <span className="required">Código postal</span>
          </label>
          <input required type="text" name="POSTALCODE" />

          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              id="app-data"
              value="app-data"
              className="checkbox"
              defaultChecked={true}
              required
            />
            <label htmlFor="app-data">
            Clicando a continuación confirmas haber leído y aceptado nuestros{" "}
              <strong>
                <Link to={PDF} target="blank" title="Términos y condiciones Comunidad Mesoestetic.(Español)">
                términos y condiciones
                </Link>
              </strong>{" "}
              para formar parte de la Comunidad mesoestetic®
            </label>
          </div>
        </div>

        <div className="actions">
          <span className="secondary" onClick={back}>
          Atrás
          </span>
          <button type="submit" className="primary form">
            <span>Finalizar test</span>
            
          </button>
        </div>
      </>
      :
      <>
        <div className="form-group">
          <label htmlFor="email">
            <span className="required">Correu electrònic</span> /
            <span className="text-light required">Correo electrónico</span>
          </label>
          <input required type="email" name="EMAIL" />
          <label htmlFor="name">
            <span className="required">Nom</span> /
            <span className="text-light required">Nombre</span>
          </label>
          <input required type="text" name="FNAME" />
          <label htmlFor="gender">
            <span className="required">Gènere</span> /
            <span className="text-light required">Género</span>
          </label>
          <select required name="GENDER" defaultValue="">
            <option value="" disabled>
              Selecciona el gènere / Selecciona el género
            </option>
            <option value="Hombre">Home / Hombre</option>
            <option value="Mujer">Dona / Mujer</option>
            <option value="Otro">Un altre / Otro</option>
          </select>
          <label htmlFor="dobdob-day">
            <span className="required">Data de naixement</span> /
            <span className="text-light required">Fecha de nacimiento</span>
          </label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              className="datefield"
              type="number"
              name="dobDay"
              required
              placeholder="Día"
              min="1"
              max="31"
              style={{marginLeft: 0}}
            />
            /
            <input
              className="datefield"
              type="number"
              name="dobMonth"
              placeholder="Mes"
              required
              min="1"
              max="12"
            />
            /
            <input
              className="datefield"
              type="number"
              name="dobYear"
              placeholder="Año"
              required
              min="1900"
              max="2020"
              style={{marginRight: 0}}

            />
          </div>
          <label htmlFor="city" style={{marginTop: "20px"}}>
            <span className="required">Codi postal</span> /
            <span className="text-light required">Código postal</span>
          </label>
          <input required type="text" name="POSTALCODE" />

          {url.includes(sorteoalma) ? 
          <>
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              id="app-data"
              value="app-data"
              className="checkbox"
              defaultChecked={true}
              required
            />
            <label htmlFor="app-data">
            Clickant a continuación confirmes haver llegit i acceptat els{" "}
              <strong>
                <Link to={PDF} target="blank" title="Términos y condiciones Comunidad Mesoestetic.(Español)">
                termes i condicions
                </Link>
              </strong>{" "}
              per formar part de la Comunitat mesoestetic® i les <strong><a target="_blank" href="https://www.mesoestetic.es/bases-legales-sorteo-festival-alma">bases legals del concurs</a> </strong>.  /
              <span className="text-light">
              Clicando a continuación confirmas haber leído y aceptado nuestros{" "}
                <Link to={PDF} target="blank" title="Términos y condiciones Comunidad Mesoestetic.(Español)">
                términos y condiciones
                </Link>{" "}
                para formar parte de la Comunidad mesoestetic® y las <a className="text-light" target="_blank" href="https://www.mesoestetic.es/bases-legales-sorteo-festival-alma">bases legales del concurso</a>.
              </span>
            </label>
          </div>
          </> : 
          <>
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              id="app-data"
              value="app-data"
              className="checkbox"
              defaultChecked={true}
              required
            />
            <label htmlFor="app-data">
            Clickant a continuación confirmes haver llegit i acceptat els{" "}
              <strong>
                <Link to={PDF} target="blank" title="Términos y condiciones Comunidad Mesoestetic.(Español)">
                termes i condicions
                </Link>
              </strong>{" "}
              per formar part de la Comunitat mesoestetic® /
              <span className="text-light">
              Clicando a continuación confirmas haber leído y aceptado nuestros{" "}
                <Link to={PDF} target="blank" title="Términos y condiciones Comunidad Mesoestetic.(Español)">
                términos y condiciones
                </Link>{" "}
                para formar parte de la Comunidad mesoestetic®
              </span>
            </label>
          </div>
          </>
          }

        </div>

        <div className="actions">
          <span className="secondary" onClick={back}>
            Enrere <span>/ Atrás</span>
          </span>
          <button type="submit" className="primary form">
            <span>Finalitzar test</span> /
            <span className="text-light">Finalizar test</span>
          </button>
        </div>
      </>
    }
    </DefaultForm>
  );
};

export default Form;
