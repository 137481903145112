import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "../../components/Form";
import { useAzafata } from '../../context/azafata.context';
import "./questionnaire.css";
const letters = ["A", "B", "C", "D", "E", "F", "H", "I", "J"];
const Questionnaire = ({
  questionnaire,
  setQuestionnaire,
  setYear,
  calculateResult,
  setCustomerName,
}) => {
  const {setComment} = useAzafata();
  const [counter, setCounter] = useState(0);
  const [answerError, setAnswerError] = useState(false);

  const [history, setHistory] = useState([]);


  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const selectAnswer = (currentCounter, index) => {
    const newQuest = { ...questionnaire };
    setHistory([...history, newQuest.questions[currentCounter].id - 1]);

    newQuest.questions[currentCounter].options.map(
      (option) => (option.selected = false)
    );
    newQuest.questions[currentCounter].options[index].selected = true;
    setQuestionnaire({ ...newQuest });
    if (newQuest.questions[currentCounter].options[index].special) {
      setAnswerError(false);
      return setCounter(() => counter + 2);
    }
    else if (newQuest.questions[currentCounter].options[index].special2) {
      setAnswerError(false);
      return setCounter(() => counter + 3);
    }
    setAnswerError(false);
    return setCounter(() => counter + 1);
  };

  const nextQuestion = (currentCounter) => {
    let correct = false;
    let moveForward = 1;
    const newQuest2 = { ...questionnaire };

    questionnaire.questions[currentCounter].options.map((option) => {
      if (option.special && option.selected) {
        correct = true;
        moveForward = 2;

      } else if (option.special2 && option.selected) {
        correct = true;
        moveForward = 3;
      } else if (option.selected) {
        correct = true;
      }
    });
    if (correct) {
      setHistory([...history, newQuest2.questions[currentCounter].id - 1]);
    }

    if (!correct) return setAnswerError(true);
    setAnswerError(false);
    return setCounter(currentCounter + moveForward);
  };
  const url = window.location.hostname;
  let castellano = false;
  const iconica = "iconica";
  const universal = "universal";
  const forms = "forms";
  const sorteoalma = "sorteofestivalalma";
  let questionnaireLength = questionnaire.questions.length;
  if(url.includes(iconica) || url.includes(universal) || url.includes(forms)) {
    castellano = true;
  }

  const back = () => {
    setAnswerError(false);
    const previousQuestionId = history.pop();
    setHistory([...history]);
    return setCounter(previousQuestionId);
  };

  return (

    
    <div className="questionnaire-container">
      {counter < questionnaireLength && questionnaire.questions && (
       <div>
        {castellano ?
          <>
          <div className="question">
            <p className="catalan">
              {questionnaire.questions[counter].text_espanol}
            </p>
          </div>
          <div className="progress-bar">
            <p className="percent">
              {(
                (100 / (questionnaireLength + 1)) *
                (counter + 1)
              ).toPrecision(2)}{" "}
              %
            </p>
            <div className="empty-bar">
              <div
                className="filled-bar"
                style={{
                  width: `${
                    (100 / (questionnaireLength + 1)) *
                    (counter + 1).toPrecision(2)
                  }%`,
                }}
              ></div>
            </div>
          </div>
          {answerError && (
            <div className="error-box">
              <p>Por favor, seleccione una respuesta.</p>
            </div>
          )}
          <div className="answers">
            {questionnaire.questions[counter].options.map((option, index) => (
              option.comment ?
              (
                <textarea 
                  key={index}
                  placeholder={option.text_espanol}
                  onChange={(e)=> handleChange(e)}
                  rows="3"
                >
                </textarea>
              )
              :
              (
              <div
                key={index}
                onClick={() => selectAnswer(counter, index)}
                className={option.selected ? "selected" : ""}
              >
                <span className="letter">{letters[index]}</span>{" "}
                {option.text_espanol}
              </div>
              )
            ))}
          </div>
          </>
          :
          <>
          <div className="question">
            <p className="catalan">
              {questionnaire.questions[counter].text_catalan}
            </p>
            <p className="espanol">
              {questionnaire.questions[counter].text_espanol}
            </p>
          </div>
          <div className="progress-bar">
            <p className="percent">
              {(
                (100 / (questionnaireLength + 1)) *
                (counter + 1)
              ).toPrecision(2)}{" "}
              %
            </p>
            <div className="empty-bar">
              <div
                className="filled-bar"
                style={{
                  width: `${
                    (100 / (questionnaireLength + 1)) *
                    (counter + 1).toPrecision(2)
                  }%`,
                }}
              ></div>
            </div>
          </div>
          {answerError && (
            <div className="error-box">
              <p>Seleccioneu una resposta</p>
              <p>Por favor, seleccione una respuesta.</p>
            </div>
          )}
          <div className="answers">
            {questionnaire.questions[counter].options.map((option, index) => (
              option.comment ?
              (
                <textarea 
                  key={index}
                  placeholder={option.text_espanol}
                  onChange={(e)=> handleChange(e)}
                  rows="3"
                >
                </textarea>
              )
              :
              (
              <div
                key={index}
                onClick={() => selectAnswer(counter, index)}
                className={option.selected ? "selected" : ""}
              >
                <span className="letter">{letters[index]}</span>{" "}
                {option.text_catalan} / <span>{option.text_espanol}</span>
              </div>
              )
            ))}
          </div>
          </>
        }
          
        </div>
      )}
      {counter === questionnaireLength && (
        <div>
          {castellano ?
            <>
            <div className="question">
              <p className="catalan">
              <strong>Rellena los siguiente datos</strong> y recibirás un correo electrónico con la promoción.
              </p>
            </div>
            <div className="progress-bar">
              <p className="percent">100 %</p>
              <div className="empty-bar">
                <div
                  className="filled-bar"
                  style={{
                    width: "100%",
                  }}
                ></div>
              </div>
            </div>
            </>
            :
            <>
            <div className="question">
            {sorteoalma ?
            <>
            <p className="catalan">
              <strong>Emplena les següent dades</strong> i participa en el sorteig  /
              </p>
              <p className="espanol">
              <strong>Rellena los siguiente datos</strong> y participa en el sorteo.
              </p>
            </> : 
            <>
            <p className="catalan">
              <strong>Emplena les següent dades</strong> i rebràs un correu electrònic amb la promoció. /
              </p>
              <p className="espanol">
              <strong>Rellena los siguiente datos</strong> y recibirás un correo electrónico con la promoción.
              </p>
            </>
            }
            </div>
            <div className="progress-bar">
              <p className="percent">100 %</p>
              <div className="empty-bar">
                <div
                  className="filled-bar"
                  style={{
                    width: "100%",
                  }}
                ></div>
              </div>
            </div>
            </>
          }
          
          <Form
            questionnaire={questionnaire}
            counter={counter}
            setCounter={setCounter}
            setYear={setYear}
            calculateResult={calculateResult}
            setCustomerName={setCustomerName}
            history={history}
            setHistory={setHistory}
          />
        </div>
      )}
      {counter < questionnaireLength && (
        
        <div className="actions">
          {counter > 0 && (
            <span className="secondary" onClick={() => back()}>
              {" "}
              {castellano ?
                <>
                Atrás{" "}
                </>
                :
                <>
                Enrere <span>/ Atrás</span>{" "}
                </>
              }
              
            </span>
          )}
          {counter === 0 && (
            <Link to="/">
              {" "}
              {castellano ? 
                <>
               Atrás{" "}
                </>
                :
                <>
                Enrere <span> / Atrás</span>{" "}
                </>
              }
            </Link>
          )}
          <span className="primary" onClick={() => nextQuestion(counter)}>
            {castellano ? 
                <>
                <span>Siguiente </span>
                </>
                :
                <>
                <span>Següent </span>/ Siguiente
                </>
              }
            
          </span>
        </div>
      )}
    </div>
  );
};
export default Questionnaire;
